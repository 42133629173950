import { OpportunityType } from "@platform-app/app/core/api/models";
import {
  WizardStep,
  OrganizationDetailsFormFields,
  AccreditationFormFields,
  OpportunitySettingsFormFields,
} from "@platform-app/app/onboarding-wizard/shared/models";

export class GetOrganizationOnboardingWizardStatus {
  static readonly type =
    "[Onboarding Wizard] Get Organization Onboarding Wizard Status";
}

export class SetCurrentOnboardingWizardStep {
  static readonly type =
    "[Onboarding Wizard] Set Current Onboarding Wizard Step";
  constructor(public payload: { step: WizardStep }) {}
}

export class UpdateOrganizationDetailsFormFields {
  static readonly type =
    "[Onboarding Wizard] Update Organization Details Form Fields";
  constructor(public payload: { fields: OrganizationDetailsFormFields }) {}
}

export class UpdateAccrediationFormFields {
  static readonly type = "[Onboarding Wizard] Update Accreditation Form Fields";
  constructor(public payload: { fields: AccreditationFormFields }) {}
}

export class UpdateOpportunitySettingsFormFields {
  static readonly type =
    "[Onboarding Wizard] Update Opportunity Settings Form Fields";
  constructor(public payload: { fields: OpportunitySettingsFormFields }) {}
}

export class UpdateCurrentFormValidity {
  static readonly type = "[Onboarding Wizard] Update Current Form Validity";
  constructor(public payload: { valid: boolean }) {}
}

export class CompleteOrganizationDetailsStep {
  static readonly type =
    "[Onboarding Wizard] Complete Organization Details Step";
}

export class CompleteAccreditationStep {
  static readonly type = "[Onboarding Wizard] Complete Accreditation Step";
}

export class AddOnboardingWizardOpportunityType {
  static readonly type =
    "[Onboarding Wizard] Add Onboarding Wizard Opportunity Type";
  constructor(
    public payload: {
      opportunityType: OpportunityType;
    },
  ) {}
}

export class RemoveOnboardingWizardOpportunityType {
  static readonly type =
    "[Onboarding Wizard] Remove Onboarding Wizard Opportunity Type";
  constructor(
    public payload: {
      opportunityType: OpportunityType;
    },
  ) {}
}

export class AddOnboardingWizardDiscipline {
  static readonly type = "[Onboarding Wizard] Add Onboarding Wizard Discipline";
  constructor(public payload: { disciplineId: number }) {}
}

export class RemoveOnboardingWizardDiscipline {
  static readonly type =
    "[Onboarding Wizard] Remove Onboarding Wizard Discipline";
  constructor(public payload: { disciplineId: number }) {}
}

export class CompleteOpportunitySettingsStep {
  static readonly type =
    "[Onboarding Wizard] Complete Opportunity Settings Step";
}

export class SkipCurrentStep {
  static readonly type = "[Onboarding Wizard] Skip Current Step";
}
