import { FormControl, FormGroup } from "@angular/forms";
import {
  AddAccreditationRequest,
  AddCredentialRequest,
  ClinicSize,
  ClinicType,
  Discipline,
  InsuranceProvision,
  OnboardingStatus,
  OnboardingWizardStep,
  SchoolType,
  UpdateAccreditationRequest,
  UpdateCredentialRequest,
} from "@platform-app/app/core/api/models";
import { Address } from "@platform-app/app/main/shared/components/address-form/address-form.models";
import { AccreditationCredentialType } from "@platform-app/app/main/organization-settings-page/opportunity-settings-page/accreditation-section/accreditation-section.models";
import {
  CompleteAccreditationStep,
  CompleteOpportunitySettingsStep,
  CompleteOrganizationDetailsStep,
} from "@platform-app/app/onboarding-wizard/shared/actions";
import { OpportunityTypeItem } from "@platform-app/app/main/shared/models";

export interface OnboardingWizardStateModel {
  currentStep: WizardStepModel;
  completed: boolean;
  steps: WizardStepModel[];
}

export interface OnboardingWizardFormsStateModel {
  organizationDetails: OrganizationDetailsFormFields | null;
  accreditationDetails: AccreditationFormFields | null;
  opportunitySettings: OpportunitySettingsFormFields | null;
  errors: string[];
  currentFormValid: boolean;
}

export enum WizardStep {
  Welcome,
  OrganizationDetails,
  Accreditation,
  OpportunitySettings,
}

export interface WizardStepModel {
  step: WizardStep;
  label?: string;
  name?: WizardStepName;
  status?: OnboardingStatus;
  isCompleted?: boolean;
  route: string;
  onboardingStep?: OnboardingWizardStep;
  completeAction?: WizardStepCompleteAction;
}

export type WizardStepName =
  | "organizationDetailsAddedStatus"
  | "accreditationAddedStatus"
  | "opportunityInterestsSetUpStatus";

export type WizardStepCompleteAction =
  | CompleteOrganizationDetailsStep
  | CompleteAccreditationStep
  | CompleteOpportunitySettingsStep;

export interface OrganizationDetailsFormGroup {
  organizationName: FormControl<string | null>;
  organizationType: FormControl<SchoolType | ClinicType | null>;
  websiteUrl: FormControl<string | null>;
  insuranceProvision?: FormControl<InsuranceProvision | null>;
  address: FormGroup;
  organizationSize?: FormControl<ClinicSize | null>;
}

export interface OrganizationDetailsFormFields {
  organizationName: string | null;
  organizationType: SchoolType | ClinicType | null;
  websiteUrl: string | null;
  address: Address | null;
  insuranceProvision?: InsuranceProvision | null;
  organizationSize?: ClinicSize | null;
}

export interface AccreditationFormFields {
  updateModelId: string | null;
  accreditation: AddAccreditationRequest | UpdateAccreditationRequest | null;
  credential: AddCredentialRequest | UpdateCredentialRequest | null;
  type: AccreditationCredentialType | null;
}

export interface OpportunitySettingsFormGroup {
  opportunityTypes: FormControl<OpportunityTypeItem[] | null>;
  disciplines: FormControl<Discipline[] | null>;
}

export interface OpportunitySettingsFormFields {
  opportunityTypes: OpportunityTypeItem[] | null;
  disciplines: Discipline[] | null;
}

export interface OnboardingWizardExitNavigationState {
  introductionDialogVisible: boolean;
}
