import { OnboardingWizardStep } from "@platform-app/app/core/api/models";
import {
  CompleteAccreditationStep,
  CompleteOpportunitySettingsStep,
  CompleteOrganizationDetailsStep,
} from "@platform-app/app/onboarding-wizard/shared/actions";
import {
  OnboardingWizardFormsStateModel,
  OnboardingWizardStateModel,
  WizardStep as WizardStep,
  WizardStepModel,
} from "@platform-app/app/onboarding-wizard/shared/models";

export const WELCOME_WIZARD_STEP: WizardStepModel = {
  step: WizardStep.Welcome,
  route: "welcome",
};

export const ORGANIZATION_DETAILS_WIZARD_STEP: WizardStepModel = {
  step: WizardStep.OrganizationDetails,
  label: "Set Up <br />Profile",
  name: "organizationDetailsAddedStatus",
  route: "organization-details",
  onboardingStep: OnboardingWizardStep.OrganizationDetails,
  completeAction: new CompleteOrganizationDetailsStep(),
};

export const OPPORTUNITY_SETTINGS_WIZARD_STEP: WizardStepModel = {
  step: WizardStep.OpportunitySettings,
  label: "Placement <br />Preferences",
  name: "opportunityInterestsSetUpStatus",
  route: "opportunity-settings",
  onboardingStep: OnboardingWizardStep.OpportunityInterests,
  completeAction: new CompleteOpportunitySettingsStep(),
};

export const DEFAULT_ONBOARDING_WIZARD_STATE: OnboardingWizardStateModel = {
  currentStep: WELCOME_WIZARD_STEP,
  completed: false,
  steps: [],
};

export const DEFAULT_ONBOARDING_WIZARD_FORMS_STATE: OnboardingWizardFormsStateModel =
  {
    organizationDetails: null,
    accreditationDetails: null,
    opportunitySettings: null,
    errors: [],
    currentFormValid: false,
  };

export const SCHOOL_WIZARD_STEPS: WizardStepModel[] = [
  WELCOME_WIZARD_STEP,
  ORGANIZATION_DETAILS_WIZARD_STEP,
  {
    step: WizardStep.Accreditation,
    label: "Accreditation/ <br />Credentialing Body",
    name: "accreditationAddedStatus",
    route: "accreditation",
    onboardingStep: OnboardingWizardStep.Accreditation,
    completeAction: new CompleteAccreditationStep(),
  },
  OPPORTUNITY_SETTINGS_WIZARD_STEP,
];

export const CLINIC_WIZARD_STEPS: WizardStepModel[] = [
  WELCOME_WIZARD_STEP,
  { ...ORGANIZATION_DETAILS_WIZARD_STEP, label: "Set Up Profile" },
  { ...OPPORTUNITY_SETTINGS_WIZARD_STEP, label: "Opportunity Settings" },
];
