import { inject, Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { OrganizationService } from "@platform-app/app/core/api/services";
import { AuthState } from "@platform-app/app/core/auth/auth.state";
import { StateToDefaultResetter } from "@platform-app/app/main/shared/state/state-to-default-resetter";
import {
  GetOrganizationOnboardingWizardStatus,
  SetCurrentOnboardingWizardStep,
  SkipCurrentStep,
} from "@platform-app/app/onboarding-wizard/shared/actions";
import {
  OnboardingWizardStateModel,
  WizardStepModel,
} from "@platform-app/app/onboarding-wizard/shared/models";
import {
  CLINIC_WIZARD_STEPS,
  DEFAULT_ONBOARDING_WIZARD_STATE,
  SCHOOL_WIZARD_STEPS,
} from "@platform-app/app/onboarding-wizard/shared/constants";
import { EMPTY, iif, Subject, switchMap, takeUntil, tap } from "rxjs";
import {
  GetOrganizationOnboardingWizardStatusSteps,
  OnboardingStatus,
  UserGroup,
} from "@platform-app/app/core/api/models";

@State<OnboardingWizardStateModel>({
  name: "OnboardingWizard",
  defaults: DEFAULT_ONBOARDING_WIZARD_STATE,
})
@Injectable()
export class OnboardingWizardState extends StateToDefaultResetter<OnboardingWizardStateModel> {
  private readonly store = inject(Store);
  private readonly organizationService = inject(OrganizationService);

  private readonly cancelPrevious$ = new Subject<void>();

  constructor() {
    super(DEFAULT_ONBOARDING_WIZARD_STATE);
  }

  @Selector()
  static currentStep(state: OnboardingWizardStateModel) {
    return state.currentStep;
  }

  @Selector()
  static completed(state: OnboardingWizardStateModel) {
    return state.completed;
  }

  @Selector()
  static steps(state: OnboardingWizardStateModel) {
    return state.steps;
  }

  @Action(SetCurrentOnboardingWizardStep)
  setCurrentOnboardingWizardStep(
    ctx: StateContext<OnboardingWizardStateModel>,
    action: SetCurrentOnboardingWizardStep,
  ) {
    const step = ctx
      .getState()
      .steps.find((s) => s.step === action.payload.step);

    if (!step) throw new Error("Invalid Current step.");

    ctx.patchState({ currentStep: step });
  }

  @Action(GetOrganizationOnboardingWizardStatus)
  getOrganizationOnboardingStatus(
    ctx: StateContext<OnboardingWizardStateModel>,
  ) {
    return this.loadOrganizationOnboardingStatus(ctx);
  }

  @Action(SkipCurrentStep)
  skipCurrentStep(ctx: StateContext<OnboardingWizardStateModel>) {
    const { currentStep } = ctx.getState();
    const organizationId = this.getCurrentOrganizationId();
    const onboardingStep = currentStep.onboardingStep;

    if (!onboardingStep) throw new Error("Unable to skip current Step.");

    const skipRequest = this.organizationService
      .organizationOrganizationIdOnboardingWizardStepsSkipPut({
        organizationId,
        body: { stepToSkip: onboardingStep },
      })
      .pipe(switchMap(() => this.loadOrganizationOnboardingStatus(ctx)));

    return iif(() => !currentStep.isCompleted, skipRequest, EMPTY);
  }

  private getCurrentOrganizationId(): string {
    const organizationId = this.store.selectSnapshot(AuthState.organizationId);

    if (!organizationId) {
      throw new Error("Current User organization not found.");
    }

    return organizationId;
  }

  private loadOrganizationOnboardingStatus(
    ctx: StateContext<OnboardingWizardStateModel>,
  ) {
    this.cancelPrevious$.next();

    const { currentStep } = ctx.getState();
    const organizationId = this.getCurrentOrganizationId();

    return this.organizationService
      .organizationOrganizationIdOnboardingWizardGet({ organizationId })
      .pipe(
        takeUntil(this.cancelPrevious$),
        tap((response) => {
          const steps = this.parseWizardStepsResponse(response.steps);
          ctx.patchState({
            steps,
            completed: response.wizardCompleted,
            currentStep: steps.find((s) => s.step === currentStep.step),
          });
        }),
      );
  }

  private parseWizardStepsResponse(
    steps: GetOrganizationOnboardingWizardStatusSteps,
  ): WizardStepModel[] {
    const roleGroup = this.store.selectSnapshot(AuthState.user)?.roleGroup;

    if (!roleGroup) throw new Error("Current user not found.");

    const statusSteps = steps as Record<string, OnboardingStatus>;

    const userSteps =
      roleGroup === UserGroup.SchoolUser
        ? SCHOOL_WIZARD_STEPS
        : CLINIC_WIZARD_STEPS;

    return userSteps.map((step) => ({
      ...step,
      status:
        step.name && statusSteps[step.name]
          ? statusSteps[step.name]
          : undefined,
      isCompleted:
        step.name && statusSteps[step.name]
          ? statusSteps[step.name] !== OnboardingStatus.Empty
          : step.isCompleted,
    }));
  }
}
