import { HttpErrorResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import {
  OnboardingStatus,
  OpportunityType,
  OrganizationType,
  UpdateAccreditationRequest,
  UpdateCredentialRequest,
  UpdateOrganizationInfoAddressInfo,
  UpdateOrganizationInfoErrorCode,
  UpdateOrganizationInfoOrganizationInfo,
  UpdateOrganizationInfoOrganizationInfoClinicInfo,
  UpdateOrganizationInfoOrganizationInfoSchoolInfo,
  UserGroup,
} from "@platform-app/app/core/api/models";
import {
  ClinicService,
  OrganizationService,
  SchoolService,
} from "@platform-app/app/core/api/services";
import { AuthState } from "@platform-app/app/core/auth/auth.state";
import { AccreditationCredentialType } from "@platform-app/app/main/organization-settings-page/opportunity-settings-page/accreditation-section/accreditation-section.models";
import { StateToDefaultResetter } from "@platform-app/app/main/shared/state/state-to-default-resetter";
import {
  AddOnboardingWizardDiscipline,
  AddOnboardingWizardOpportunityType,
  CompleteAccreditationStep,
  CompleteOpportunitySettingsStep,
  CompleteOrganizationDetailsStep,
  GetOrganizationOnboardingWizardStatus,
  RemoveOnboardingWizardDiscipline,
  RemoveOnboardingWizardOpportunityType,
  SkipCurrentStep,
  UpdateAccrediationFormFields,
  UpdateCurrentFormValidity,
  UpdateOpportunitySettingsFormFields,
  UpdateOrganizationDetailsFormFields,
} from "@platform-app/app/onboarding-wizard/shared/actions";
import { DEFAULT_ONBOARDING_WIZARD_FORMS_STATE } from "@platform-app/app/onboarding-wizard/shared/constants";
import {
  OnboardingWizardFormsStateModel,
  WizardStep,
} from "@platform-app/app/onboarding-wizard/shared/models";
import { OnboardingWizardState } from "@platform-app/app/onboarding-wizard/shared/onboarding-wizard.state";
import { catchError, iif, Observable, of, switchMap } from "rxjs";

@State<OnboardingWizardFormsStateModel>({
  name: "OnboardingWizardForms",
  defaults: DEFAULT_ONBOARDING_WIZARD_FORMS_STATE,
})
@Injectable()
export class OnboardingWizardFormsState extends StateToDefaultResetter<OnboardingWizardFormsStateModel> {
  private readonly organizationService = inject(OrganizationService);
  private readonly schoolService = inject(SchoolService);
  private readonly clinicService = inject(ClinicService);
  private readonly store = inject(Store);

  constructor() {
    super(DEFAULT_ONBOARDING_WIZARD_FORMS_STATE);
  }

  @Selector()
  static errors(state: OnboardingWizardFormsStateModel) {
    return state.errors;
  }

  @Selector()
  static organizationDetails(state: OnboardingWizardFormsStateModel) {
    return state.organizationDetails;
  }

  @Selector()
  static accreditationDetails(state: OnboardingWizardFormsStateModel) {
    return state.accreditationDetails;
  }

  @Selector()
  static opportunitySettings(state: OnboardingWizardFormsStateModel) {
    return state.opportunitySettings;
  }

  @Selector()
  static currentFormValid(state: OnboardingWizardFormsStateModel) {
    return state.currentFormValid;
  }

  @Action(UpdateOrganizationDetailsFormFields)
  updateOrganizationDetailsFormFields(
    ctx: StateContext<OnboardingWizardFormsStateModel>,
    action: UpdateOrganizationDetailsFormFields,
  ) {
    ctx.patchState({ organizationDetails: action.payload.fields });
  }

  @Action(UpdateAccrediationFormFields)
  updateAccrediationFormFields(
    ctx: StateContext<OnboardingWizardFormsStateModel>,
    action: UpdateAccrediationFormFields,
  ) {
    ctx.patchState({ accreditationDetails: action.payload.fields });
  }

  @Action(UpdateOpportunitySettingsFormFields)
  updateOpportunitySettingsFormFields(
    ctx: StateContext<OnboardingWizardFormsStateModel>,
    action: UpdateOpportunitySettingsFormFields,
  ) {
    ctx.patchState({
      opportunitySettings: action.payload.fields,
      // OpportunitySettings step doesn't require form submission/validation due to autosaving,
      // so we ensure the form is marked as valid here.
      currentFormValid: true,
    });
  }

  @Action(UpdateCurrentFormValidity)
  updateCurrentFormValidity(
    ctx: StateContext<OnboardingWizardFormsStateModel>,
    action: UpdateCurrentFormValidity,
  ) {
    ctx.patchState({ currentFormValid: action.payload.valid });
  }

  @Action(CompleteOrganizationDetailsStep)
  completeOrganizationDetailsStep(
    ctx: StateContext<OnboardingWizardFormsStateModel>,
  ) {
    const jsonBody = this.getOrganizationDetailsBody(ctx);

    return this.organizationService
      .organizationInfoPut({
        body: {
          Json: jsonBody,
        },
      })
      .pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse && error.status === 400) {
            const errors = JSON.parse(error.error).errors ?? error.error;

            ctx.patchState({
              errors:
                errors?.map(
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (e: any) => {
                    return e.code
                      ? (e.code as UpdateOrganizationInfoErrorCode)
                      : (e as string);
                  },
                ) || [],
            });
          }
          return of(error);
        }),
        switchMap(() => {
          const stateSnapshot = ctx.getState();
          return stateSnapshot.errors.length
            ? of(null)
            : this.store.dispatch(new GetOrganizationOnboardingWizardStatus());
        }),
      );
  }

  @Action(CompleteAccreditationStep)
  completeAccreditationStep(
    ctx: StateContext<OnboardingWizardFormsStateModel>,
  ) {
    const steps = this.store.selectSnapshot(OnboardingWizardState.steps);
    const accreditationStep = steps.find(
      (s) => s.step === WizardStep.Accreditation,
    );

    if (!accreditationStep) throw new Error("Accreditation step is missing.");

    const { accreditationDetails } = ctx.getState();

    if (!accreditationDetails)
      throw new Error("Accreditation details are missing.");

    if (
      (accreditationDetails.type ===
        AccreditationCredentialType.Accreditation &&
        !accreditationDetails.accreditation) ||
      (accreditationDetails.type ===
        AccreditationCredentialType.Credentialing &&
        !accreditationDetails.credential)
    )
      throw new Error("Accreditation details are invalid.");

    const organization = this.getCurrentOrganization();

    const accreditationRequest = iif(
      () =>
        accreditationStep.status === OnboardingStatus.Filled &&
        !!accreditationDetails.updateModelId,
      this.schoolService.updateAccreditation({
        schoolId: organization.id,
        accreditationId: accreditationDetails.updateModelId!,
        body: accreditationDetails.accreditation as UpdateAccreditationRequest,
      }),
      this.schoolService.addAccreditation({
        schoolId: organization.id,
        body: accreditationDetails.accreditation!,
      }),
    );

    const credentialRequest = iif(
      () =>
        accreditationStep.status === OnboardingStatus.Filled &&
        !!accreditationDetails.updateModelId,
      this.schoolService.updateCredential({
        schoolId: organization.id,
        credentialId: accreditationDetails.updateModelId!,
        body: accreditationDetails.credential as UpdateCredentialRequest,
      }),
      this.schoolService.addCredential({
        schoolId: organization.id,
        body: accreditationDetails.credential!,
      }),
    );

    return iif(
      () =>
        accreditationDetails.type === AccreditationCredentialType.Accreditation,
      accreditationRequest,
      credentialRequest,
    ).pipe(
      switchMap(() =>
        this.store.dispatch(new GetOrganizationOnboardingWizardStatus()),
      ),
    );
  }

  // OpportunitySettings step doesn't require completion,
  // but we ensure the user can exit it via 'Skip Current Step'.
  @Action(CompleteOpportunitySettingsStep)
  completeOpportunitySettingsStep(
    _: StateContext<OnboardingWizardFormsStateModel>,
  ) {
    const currentStep = this.store.selectSnapshot(
      OnboardingWizardState.currentStep,
    );

    if (currentStep.step !== WizardStep.OpportunitySettings)
      throw new Error("Cannot complete Opportunity Settings step.");

    return this.store.dispatch(new SkipCurrentStep());
  }

  @Action(AddOnboardingWizardOpportunityType)
  addOnboardingWizardOpportunityType(
    _: StateContext<OnboardingWizardFormsStateModel>,
    action: AddOnboardingWizardOpportunityType,
  ) {
    return this.addOpportunityTypeRequest(action.payload.opportunityType).pipe(
      switchMap(() =>
        this.store.dispatch(new GetOrganizationOnboardingWizardStatus()),
      ),
    );
  }

  @Action(RemoveOnboardingWizardOpportunityType)
  removeOnboardingWizardOpportunityType(
    _: StateContext<OnboardingWizardFormsStateModel>,
    action: RemoveOnboardingWizardOpportunityType,
  ) {
    return this.removeOpportunityTypeRequest(action.payload.opportunityType);
  }

  @Action(AddOnboardingWizardDiscipline)
  addOnboardingWizardDiscipline(
    _: StateContext<OnboardingWizardFormsStateModel>,
    action: AddOnboardingWizardDiscipline,
  ) {
    return this.addDisciplineRequest(action.payload.disciplineId).pipe(
      switchMap(() =>
        this.store.dispatch(new GetOrganizationOnboardingWizardStatus()),
      ),
    );
  }

  @Action(RemoveOnboardingWizardDiscipline)
  removeOnboardingWizardDiscipline(
    _: StateContext<OnboardingWizardFormsStateModel>,
    action: RemoveOnboardingWizardDiscipline,
  ) {
    return this.removeDisciplineRequest(action.payload.disciplineId);
  }

  private getOrganizationDetailsBody(
    ctx: StateContext<OnboardingWizardFormsStateModel>,
  ): UpdateOrganizationInfoOrganizationInfo {
    const organizationDetails = ctx.getState().organizationDetails;

    if (!organizationDetails)
      throw new Error("Organization details are missing");

    const currentOrganization = this.getCurrentOrganization();

    return {
      id: currentOrganization.id,
      name: organizationDetails.organizationName!,
      websiteUrl: organizationDetails.websiteUrl,
      organizationType: currentOrganization.type,
      insuranceProvision: organizationDetails.insuranceProvision,
      school:
        currentOrganization.type === OrganizationType.School
          ? ({
              typeId: organizationDetails.organizationType!.id,
              studentEnrollment: null,
            } as UpdateOrganizationInfoOrganizationInfoSchoolInfo)
          : null,
      clinic:
        currentOrganization.type === OrganizationType.Clinic
          ? ({
              typeId: organizationDetails.organizationType!.id,
              size: organizationDetails.organizationSize ?? null,
            } as UpdateOrganizationInfoOrganizationInfoClinicInfo)
          : null,
      address: organizationDetails.address as UpdateOrganizationInfoAddressInfo,
    } satisfies UpdateOrganizationInfoOrganizationInfo;
  }

  private getCurrentOrganization(): {
    id: string;
    type: OrganizationType;
  } {
    const user = this.store.selectSnapshot(AuthState.user);
    if (!user) throw new Error("Current User not found.");

    const organizationId = this.store.selectSnapshot(AuthState.organizationId);
    if (!organizationId)
      throw new Error("Current User organization not found.");

    return {
      id: organizationId,
      type:
        user.roleGroup === UserGroup.SchoolUser
          ? OrganizationType.School
          : OrganizationType.Clinic,
    };
  }

  private addOpportunityTypeRequest(
    opportunityType: OpportunityType,
  ): Observable<void> {
    const organization = this.getCurrentOrganization();

    return organization.type === OrganizationType.School
      ? this.schoolService.schoolSchoolIdOpportunityTypeOpportunityTypePost({
          schoolId: organization.id,
          opportunityType: opportunityType,
        })
      : this.clinicService.clinicClinicIdOpportunityTypeOpportunityTypePost({
          clinicId: organization.id,
          opportunityType: opportunityType,
        });
  }

  private removeOpportunityTypeRequest(
    opportunityType: OpportunityType,
  ): Observable<void> {
    const organization = this.getCurrentOrganization();

    return organization.type === OrganizationType.School
      ? this.schoolService.schoolSchoolIdOpportunityTypeOpportunityTypeDelete({
          schoolId: organization.id,
          opportunityType: opportunityType,
        })
      : this.clinicService.clinicClinicIdOpportunityTypeOpportunityTypeDelete({
          clinicId: organization.id,
          opportunityType: opportunityType,
        });
  }

  private addDisciplineRequest(disciplineId: number): Observable<void> {
    const organization = this.getCurrentOrganization();

    return organization.type === OrganizationType.School
      ? this.schoolService.schoolSchoolIdOpportunityDisciplineDisciplineIdPost({
          schoolId: organization.id,
          disciplineId,
        })
      : this.clinicService.clinicClinicIdOpportunityDisciplineDisciplineIdPost({
          clinicId: organization.id,
          disciplineId,
        });
  }

  private removeDisciplineRequest(disciplineId: number): Observable<void> {
    const organization = this.getCurrentOrganization();

    return organization.type === OrganizationType.School
      ? this.schoolService.schoolSchoolIdOpportunityDisciplineDisciplineIdDelete(
          {
            schoolId: organization.id,
            disciplineId,
          },
        )
      : this.clinicService.clinicClinicIdOpportunityDisciplineDisciplineIdDelete(
          {
            clinicId: organization.id,
            disciplineId,
          },
        );
  }
}
